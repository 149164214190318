const phones = {
    'customessaymeister.com': [
        {
            phoneNumber: '1-888-823-9060',
            country: 'US',
            flag: '/img/header/united-states.svg',
            alt: 'United State of America',
            icon: 'phone'
        },
        {
            phoneNumber: '1-302-351-4405',
            country: 'US',
            flag: '/img/header/united-states.svg',
            alt: 'United State of America',
            icon: 'phone'
        },
        {
            phoneNumber: '1-812-284-0883',
            country: 'US',
            flag: '/img/header/united-states.svg',
            alt: 'United State of America',
            icon: 'fax'
        }
    ],
    'dreamessays.com': [
        {
            phoneNumber: '1-888-823-9060',
            country: 'US',
            flag: '/img/header/united-states.svg',
            alt: 'United State of America',
            icon: 'phone'
        },
        {
            phoneNumber: '1-302-351-4405',
            country: 'US',
            flag: '/img/header/united-states.svg',
            alt: 'United State of America',
            icon: 'phone'
        },
        {
            phoneNumber: '1-812-284-0883',
            country: 'US',
            flag: '/img/header/united-states.svg',
            alt: 'United State of America',
            icon: 'fax'
        }
    ],
    'professays.com': [
        {
            phoneNumber: '1-888-823-9060',
            country: 'US',
            flag: '/img/header/united-states.svg',
            alt: 'United State of America',
            icon: 'phone'
        },
        {
            phoneNumber: '1-302-351-4405',
            country: 'US',
            flag: '/img/header/united-states.svg',
            alt: 'United State of America',
            icon: 'phone'
        },
        {
            phoneNumber: '1-812-284-0883',
            country: 'US',
            flag: '/img/header/united-states.svg',
            alt: 'United State of America',
            icon: 'fax'
        }
    ],
    'genuinewriting.com': [
        {
            phoneNumber: '1-888-823-9060',
            country: 'US',
            flag: '/img/header/united-states.svg',
            alt: 'United State of America',
            icon: 'phone'
        },
        {
            phoneNumber: '1-302-351-4405',
            country: 'US',
            flag: '/img/header/united-states.svg',
            alt: 'United State of America',
            icon: 'phone'
        },
        {
            phoneNumber: '1-812-284-0883',
            country: 'US',
            flag: '/img/header/united-states.svg',
            alt: 'United State of America',
            icon: 'fax'
        }
    ],
    default: null
}

module.exports = { phones }
