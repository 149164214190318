var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket-card-header"},[_c('div',{staticClass:"ticket-card-header__left-side",class:{'ticket-card-header__left-side-details' : _vm.$route.name.includes('ticket_')}},[_c('div',{staticClass:"ticket-card-header__left-side-date-wrapper"},[(_vm.mode === 'list')?_c('span',{staticClass:"ticket-card-header__left-side-index"},[_vm._v(" "+_vm._s(_vm.index + 1)+" ")]):_vm._e(),_c('span',{staticClass:"ticket-card-header__left-side-date"},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm._f("moment_from")(_vm.ticket.created_at))+" ago ")])])]),_c('span',{staticClass:"ticket-card-header__left-side-status card_title__status",class:_vm.getStatusClassName},[_vm._v(" "+_vm._s(_vm.statusOpen)+" ")])]),_c('div',{staticClass:"ticket-card-header__right-side"},[_c('div',{staticClass:"ticket-card-header__top"},[(_vm.ticket.ticketid)?_c(_vm.mode === 'list' ? 'router-link' : 'span',{tag:"router-link",staticClass:"ticket-card-header__top-title",attrs:{"to":_vm.mode === 'list' && {
                    name: 'ticket_details',
                    params: {
                        id: _vm.ticket.ticketid
                    }
                }}},[_c('div',{staticClass:"ticket-card-header__top-topic"},[_c('span',{staticClass:"ticket-card-header__top__subtopic-type"},[(_vm.longNamesType)?_c('span',[_vm._v(_vm._s(_vm.longNamesType))]):_vm._e(),(_vm.longNamesCategory)?_c('span',[_vm._v(" "+_vm._s(_vm.longNamesCategory))]):_vm._e(),(_vm.longNamesResolution)?_c('span',[_vm._v(" "+_vm._s(_vm.longNamesResolution))]):_vm._e()]),_c('span',{staticClass:"ticket-card-header__top-subtopic ticket-card-header__top-subtopic--title"},[_vm._v(" «"+_vm._s(_vm.titleFragment)+"» "),_c('span',{staticClass:"custom-tooltip",class:{'ticket-card-header__top-custom-tooltip-none' : !_vm.isShowToolTip}},[_c('p',[_vm._v("!")]),_c('span',[_vm._v(" "+_vm._s(_vm.ticket.subject)+" ")])])])])]):_vm._e(),_c('div',{staticClass:"ticket-card-header__top-stats"},[_c('div',{staticClass:"ticket-card-header__top-bell-wrapper ",class:_vm.getBubles === 0 ? 'grayscale' : ''},[_c('span',{staticClass:"ticket-card-header__top-bell"},[_c('Bell'),(_vm.getBubles > 0)?_c('span',{staticClass:"bubble--unread bubble--unread-header"},[_vm._v(" "+_vm._s(_vm.getBubles)+" "),_c('span',{staticClass:"tool"},[(_vm.unreadMessages > 0)?_c('div',[_vm._v(_vm._s(_vm.unreadMessages)+" new messages")]):_vm._e()])]):_vm._e()],1)])])],1),_c('div',{staticClass:"ticket-card-header__bottom"},[_c('message',{attrs:{"message":_vm.ticket.last_message}}),_c('div',{staticClass:"ticket-card-header__bottom-btn"},[(!_vm.$route.name.includes('ticket_'))?_c('router-link',{staticClass:"ticket-card-header__bottom-btn-details",attrs:{"to":{
                        name: 'ticket_details',
                        params: { id: _vm.ticket.ticketid }
                    }}},[_vm._v(" View details ")]):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }