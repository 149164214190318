<template>
    <div class="ticket_card_body">
        <div class="card_body__row">
            <div class="card_body__col">
                <!-- DEADLINE -->
                <div class="card_body__detail">
                    <div class="card_body__detail-title">
                        {{ ticket.created_at | moment_bidding_text }}
                    </div>
                    <div class="card_body__detail-counter">
                        <Clock />
                        {{ ticket.created_at | moment_from }} ago
                    </div>
                </div>
                <!-- Ticket ID -->
                <div
                    v-if="ticket.ticketid"
                    class="card_body__detail"
                >
                    <div class="card_body__detail-title">
                        Ticket ID
                    </div>
                    <div class="card_body__detail-counter">
                        <Clipboard />
                        {{ ticket.ticketid }}
                    </div>
                </div>

                <!-- orderid -->
                <div
                    v-if="ticket.orderid"
                    class="card_body__detail"
                >
                    <div class="card_body__detail-title">
                        Order ID
                    </div>
                    <div class="card_body__detail-counter">
                        <Clipboard />
                        <router-link
                            :to="{
                                name: 'order_details',
                                params: {
                                    id: ticket.orderid
                                }
                            }"
                            class="billing_order_orderid billing_order_orderid--ml"
                        >
                            {{ ticket.orderid }}
                        </router-link>
                    </div>
                </div>

                <!-- MESSAGES -->
                <div
                    v-if="ticket.counters.messages_count > 0"
                    class="card_body__detail"
                >
                    <div class="card_body__detail-title">
                        Comments
                    </div>
                    <div class="card_body__detail-counter">
                        <MassageSquare />
                        {{ ticket.counters.messages_count }}
                        <span
                            v-if="bubles > 0"
                            class="bubble--unread"
                        >
                            {{ bubles }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card_body__row card_body__row--border-n">
            <support-card-chat
                :ticket="ticket"
                :messages="messages"
                @markAsRead="$emit('markAsRead', {ticketid: ticket.ticketid})"
            />
        </div>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin.js'

import SupportCardChat from '@/components/account/Cards/SupportCard/SupportCardChat.vue'
import MassageSquare from '@/components/icons/Message-square'
import Clock from '@/components/icons/File'
import Clipboard from '@/components/icons/Сlipboard'

export default {
    components: {
        MassageSquare,
        Clipboard,
        Clock,
        SupportCardChat
    },
    mixins: [filtersMixin],
    props: {
        ticket: {
            type: Object,
            required: true
        },
        openCard: {
            type: Boolean
        },
        bubles: {
            type: Number
        },
        messages: {
            type: Array
        }
    }
}
</script>

<style lang="scss">
.ticket_card {
    &_body {
        padding: 0 20px ;
        background: #fff;
        // border-radius: $main-border-radius;
        .card_body__row {
            &:first-child {
                border: none;
                margin-top: 0;
            }
        }
    }
}
</style>
