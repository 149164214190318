const { phones } = require('../phonesConfig.js')

/* eslint-disable class-methods-use-this */
module.exports = class Environment {
    constructor(domain) {
        this.domain = domain;
    }

    get styleSetting() {
        const setting = {
            url: `@/assets/site_specific/${process.env.VUE_APP_DOMAIN}/styles`,
            imagesSrc: `src/assets/site_specific/${process.env.VUE_APP_DOMAIN}/images`
        };
        switch (process.env.VUE_APP_DOMAIN) {
        case 'customessaymeister.com':
            setting.metaName = 'Customessay Meister';
            setting.color = '#ff690c';
            setting.title = 'Best Essay Writers Online | CustomEssayMeister.com'
            break;

        case 'dreamessays.com':
            setting.metaName = 'Dreamessays';
            setting.color = '#6187fd';
            setting.title = 'Best Essay Writers Online | DreamEssays.com'
            break;

        case 'professays.com':
            setting.metaName = 'Professays';
            setting.color = '#4a9ce1';
            setting.title = 'Best Essay Writers Online | ProfEssays.com'
            break;

        case 'solidpapers.com':
            setting.metaName = 'Solidpapers';
            setting.color = '#4a9ce1';
            setting.title = 'Best Essay Writers Online | SolidPapers.com'
            break;

        case 'genuinewriting.com':
            setting.metaName = 'Genuinewriting';
            setting.color = '#CC3636';
            setting.title = 'Best Essay Writers Online | GenuineWriting.com'
            break;

        case 'bili.pro':
            setting.metaName = 'bili.pro';
            setting.color = '#ff690c';
            setting.title = 'Best Essay Writers Online | bili.pro'
            break;

        case 'paper.coach':
            setting.metaName = 'Paper Coach';
            setting.color = '#ff690c';
            setting.title = 'Best Essay Writers Online | Paper.Coach'
            break;

        case 'kontentify.io':
            setting.metaName = 'Kontentify.io';
            setting.color = '#1161e0';
            setting.title = 'Your content solution | Kontentify.io'
            break;

        default:
            console.error('\x1b[41m', 'Need add case for VUE_APP_DOMAIN in /src/helpers/environment/index.js')
            return false;
        }
        return setting;
    }

    get getPhones() {
        return phones[process.env.VUE_APP_DOMAIN] || phones.default
    }
};
